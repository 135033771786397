import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import Dropzone from 'react-dropzone';
import { Buffer } from 'buffer';
import { Container, Box, Button, MenuItem, Typography, AppBar, Toolbar, IconButton, Link, Menu } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { saveAs } from 'file-saver';
import ReactPlayer from 'react-player';
import { create } from 'ipfs-http-client';
import { Brightness4 as DarkModeIcon, Brightness7 as LightModeIcon } from '@mui/icons-material';

import './App.css';

const projectId = '2DBM8M1VMImQ5vl4IQxYQzI8uKg';
const projectSecret = 'f34bbedac4f01e7e79a9c99d04ba08cf';
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const client = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  apiPath: '/api/v0',
  headers: {
    authorization: auth,
  },
});

const App = () => {
  const [encryptKey, setEncryptKey] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [encryptedData, setEncryptedData] = useState('');
  const [encryptedDataPasted, setEncryptedDataPasted] = useState('');
  const [decryptedData, setDecryptedData] = useState(null);
  const [fileFormat, setFileFormat] = useState('PNG');
  const [anchorEl, setAnchorEl] = useState(null);
  const [ipfsHash, setIpfsHash] = useState('');
  const [themeMode, setThemeMode] = useState('light'); // Default theme mode is light

  const [decodeSuccess, setDecodeSuccess] = useState(true); // Initially assuming decode is successful



  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none', // Change text case to lowercase
          },
        },
      },
    },
    palette: {
      mode: themeMode, // Theme mode controlled by state
      background: {
        default: themeMode === 'light' ? '#fff' : 'darkgray', // Custom background color for dark mode
      },
    },
  });


  const toggleThemeMode = () => {
    setThemeMode(prevMode => (prevMode === 'light' ? 'dark' : 'light')); // Toggle between light and dark mode
  };

  const handleEncryptKey = (e) => {
    setEncryptKey(e.target.value);
  };

  const handleFileFormatChange = (e) => {
    setFileFormat(e.target.value);
  };

  const handleImageUpload = async (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const handleEncrypt = () => {
    if (!file || !encryptKey) return;

    const reader = new FileReader();
    reader.onload = () => {
      // Check if the file size is within the limit (e.g., 100 MB)
      const fileSizeLimit = 100 * 1024 * 1024; // 100 MB
      if (reader.result.byteLength > fileSizeLimit) {
        console.error('File size exceeds the limit');
        return;
      }

      // Convert the ArrayBuffer to a WordArray
      const wordArray = CryptoJS.lib.WordArray.create(reader.result);

      // Encrypt the WordArray
      const encrypted = CryptoJS.AES.encrypt(wordArray, encryptKey);
      const encryptedBytes = encrypted.toString();
      setEncryptedData(encryptedBytes);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleDecryptKey = (e) => {
    setEncryptKey(e.target.value);
  };

  // const handleDecrypt = () => {
  //   if (!encryptedData || !encryptKey) return;

  //   const decrypted = CryptoJS.AES.decrypt(encryptedData, encryptKey);
  //   const decryptedBytes = decrypted.toString(CryptoJS.enc.Latin1);
  //   const bytes = new Uint8Array(decryptedBytes.length);
  //   for (let i = 0; i < decryptedBytes.length; i++) {
  //     bytes[i] = decryptedBytes.charCodeAt(i);
  //   }
  //   const blob = new Blob([bytes], { type: `image/${fileFormat.toLowerCase()}` });
  //   setDecryptedData(URL.createObjectURL(blob));
  // };

  const handleDecrypt = () => {
    if (!encryptedDataPasted || !encryptKey) return;

    const decrypted = CryptoJS.AES.decrypt(encryptedDataPasted, encryptKey);
    const decryptedBytes = decrypted.toString(CryptoJS.enc.Latin1);
    const bytes = new Uint8Array(decryptedBytes.length);
    for (let i = 0; i < decryptedBytes.length; i++) {
      bytes[i] = decryptedBytes.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: `image/${fileFormat.toLowerCase()}` });
    setDecryptedData(URL.createObjectURL(blob));
    setDecodeSuccess(true); // Assuming decode is successful initially

    // Check if decryption was unsuccessful due to incorrect key
    if (!blob.size) {
      setDecodeSuccess(false); // Set decode success to false
    }
  };


  const handleDownload = () => {
    if (!decryptedData) return;
    saveAs(decryptedData, `decrypted_image.${fileFormat.toLowerCase()}`);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUploadToIPFS = async () => {
    if (!encryptedData) return;

    try {
      const added = await client.add(encryptedData);
      const url = `https://freeweb3.infura-ipfs.io/ipfs/${added.path}`;
      setIpfsHash(url);
      console.log('IPFS URL:', url);
    } catch (error) {
      console.log('Error uploading to IPFS:', error);
    }
  };




  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" style={{ backgroundColor: themeMode === 'light' ? 'snow' : 'darkgray', minHeight: '100vh' }}>

        <AppBar position="static">
          <Toolbar>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                <a href="https://lex.freeweb3.com" target="_blank" rel="noopener noreferrer">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/QmQSqM3BbFrPtLqoPjiVoGLDVNkfxDUXTjZBjTKAYnT6VT" alt="Web3 Legal Engineering" style={{ width: '200px', marginRight: '20px' }} />
                </a>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ mr: 2 }}>
                  <a href="https://lexautomatico.com/auth/jwt/login?returnTo=%2Fdashboard%2Flegal-resources" target="blank" rel="noopener noreferrer" style={{ color: 'khaki' }}>Dashboard</a>
                </Typography>
                <Typography variant="h6" sx={{ mr: 2 }}>
                  <a href="https://artifacts.freeweb3.com" target="blank" rel="noopener noreferrer" style={{ color: 'khaki' }}>Artifacts</a>
                </Typography>
                <Typography variant="h6">
                  <a href="https://web3legaltools.com" target="blank" rel="noopener noreferrer" style={{ color: 'khaki' }}>Web3 Legal Tools</a>
                </Typography>
                <MenuItem onClick={toggleThemeMode}>{themeMode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}</MenuItem>

                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ ml: 2, display: { xs: 'block', sm: 'none' } }}
                  onClick={handleMenuClick}
                >
                  <MenuIcon />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleMenuClose}><a href="https://lexautomatico.com/auth/jwt/login?returnTo=%2Fdashboard%2Flegal-resources" target="blank" rel="noopener noreferrer" style={{ color: 'khaki' }}>Dashboard</a></MenuItem>
                  <MenuItem onClick={handleMenuClose}><a href="https://artifacts.freeweb3.com" target="blank" rel="noopener noreferrer" style={{ color: 'khaki' }}>Artifacts</a></MenuItem>
                  <MenuItem onClick={handleMenuClose}><a href="https://web3legaltools.com" target="blank" rel="noopener noreferrer" style={{ color: 'khaki' }}>Tools</a></MenuItem>
                </Menu>
              </Box>
            </Box>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>
          </Toolbar>

        </AppBar>

        
        <div style={{ width: '100%', overflow: 'hidden' }}>
          <Link href="https://upload.freeweb3.com" target="_blank" rel="noopener noreferrer">
            <img
              src="https://freeweb3.infura-ipfs.io/ipfs/QmPGB9N5RTsZTzW2i98kQxbtnut9mCAtk3bCkSiWm8SdP3"
              alt="FreeWeb3"
              style={{ width: '100%', height: 'auto', display: 'block' }}
            />
          </Link>
        </div>



        <Box>
          {/* <h1>Image Encrypt, Upload and Decode</h1> */}
          {/* <hr /> */}

          <Box
            margin="auto"
            padding="20px"
            marginTop="20px"
            bgcolor={theme.palette.mode === 'light' ? 'mintcream' : 'silver'}
            sx={{
              border: '4pt solid gray',
              borderRadius: '8px',
            }}
          >
            <h2>Encrypt an Image</h2>

            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box width="calc(50% - 10px)">
                <label htmlFor="fileFormat">Select File Format:</label>

                <select
                  id="fileFormat"
                  value={fileFormat}
                  onChange={handleFileFormatChange}
                  style={{ width: '100%', marginBottom: '10px' }}
                >
                  <option value="PNG">PNG</option>
                  <option value="JPG">JPG</option>
                  <option value="JPEG">JPEG</option>
                  <option value="GIF">GIF</option>
                </select>
              </Box>
              <Box width="calc(50% - 10px)" mr="10px">
                <label htmlFor="fileFormat">Select Encrypt/Decode Key:</label>
                <input
                  type="text"
                  placeholder="Enter encryption key"
                  value={encryptKey}
                  onChange={handleEncryptKey}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </Box>
            </Box>



            <br />

            <Box display="flex" justifyContent="space-between" flexWrap="wrap"> {/* Add flex styles */}
              <Box width={{ xs: '100%', sm: 'calc(50% - 10px)' }}> {/* Adjust width based on screen size */}
                {/* File input */}
                <input
                  id="fileInput"
                  type="file"
                  onChange={(e) => handleImageUpload(e.target.files)}
                  style={{ display: 'none' }}
                />

                <Button
                  variant="contained"
                  component="label"

                  style={{
                    backgroundColor: 'royalblue',
                    color: 'white',
                    border: 'none',
                    padding: '16px 32px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    fontSize: '1.2rem',
                    marginTop: '10px', // Add some margin to separate from the image
                    width: '100%'
                  }}

                >
                  Browse for Image to Encrypt
                  <input type="file" onChange={(e) => handleImageUpload(e.target.files)} style={{ display: 'none' }} />



                </Button>
              </Box>

              <Box width={{ xs: '100%', sm: 'calc(50% - 10px)' }} style={{ marginTop: '16px' }}> {/* Adjust width based on screen size */}
                {/* Dropzone */}
                <Box style={{ backgroundColor: theme.palette.primary.main, padding: theme.spacing(2) }}>
                  <Dropzone onDrop={handleImageUpload}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        style={{
                          outline: '3px dashed black',
                          background: 'lightgray',
                          padding: '40px',
                          textAlign: 'center',
                        }}
                      >
                        <input {...getInputProps()} />
                        <p>Or drag and drop an image here.</p>
                      </div>
                    )}
                  </Dropzone>
                </Box>
              </Box>
            </Box>

            <Box
              width="100%"
              margin="auto"
              textAlign="center"
              display="flex"
              justifyContent="flex-end" // Right justify the button
            >
              <Box width={{ xs: '100%', sm: '50%' }}>
                {fileName && <p>Selected File: {fileName}</p>}
                <button
                  onClick={handleEncrypt}
                  style={{
                    backgroundColor: 'maroon',
                    color: 'white',
                    border: 'none',
                    padding: '16px 32px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    fontSize: '1.2rem',
                    width: '100%', // Ensure button takes up entire width of its container
                  }}
                >
                  Encrypt the Image File
                </button>
              </Box>
            </Box>
          </Box>

          <br />

          {encryptedData && (
            <div>
              <h3>Encrypted Data</h3>
              <div style={{ position: 'relative', width: '100%' }}>
                <textarea
                  value={encryptedData}
                  readOnly
                  style={{ width: '100%', height: '150px', marginBottom: '10px' }}
                />
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(encryptedData);
                  }}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-label="Copy Encrypted Data"
                    role="img"
                  >
                    <title>Copy Encrypted Data</title>
                    <desc>Copy Encrypted Data</desc>
                    <path fill="none" d="M0 0h24v24H0V0z" />
                    <path fill="royalblue" d="M17 1H7c-1.1 0-1.99.9-1.99 2L5 21c0 1.1.89 2 1.99 2H17c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 18H7V5h10v14zM15 3H9c-.55 0-1 .45-1 1v1h2V5h4v1h2V4c0-.55-.45-1-1-1zm3 4H6c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1z" />
                  </svg>
                </button>
              </div>







              <br />
              <br />
              <Button
                onClick={handleUploadToIPFS}
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: 'royalblue',
                  color: 'white',
                  border: 'none',
                  padding: '16px 32px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  fontSize: '1.2rem',
                  marginTop: '10px', // Add some margin to separate from the image
                  width: '25%'
                }}
              >




                Upload to IPFS
              </Button>
              {ipfsHash && (
                <div>
                  <h4>IPFS Hash:</h4>
                  <p>{ipfsHash}</p>
                </div>
              )}
            </div>
          )}

          <Box
            mt={4}
            textAlign="center"
            sx={{
              width: '90%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* Center the content */}
            <Typography variant="h4" gutterBottom>
              Demonstration Video
            </Typography>
            <ReactPlayer
              url="https://freeweb3.infura-ipfs.io/ipfs/QmYTraXQaPbXcv6vK2eaPrtz3icDJcfrjpHm59xbL2PPqL"
              controls={true}
              width="100%"
              config={{
                file: {
                  attributes: {
                    poster: 'https://freeweb3.infura-ipfs.io/ipfs/QmVTt4f3nyWAudnDwrFHATTSbDzsTpz9ZEReAotcLrk2Kf'
                  }
                }
              }}
            />
          </Box>
          <br />
          <hr />



          <Box
            margin="auto"
            padding="20px"
            marginTop="20px"
            bgcolor={theme.palette.mode === 'light' ? 'mintcream' : 'silver'}
            sx={{
              border: '4pt solid gray',
              borderRadius: '8px',
            }}
          >
            <div>
              <h2>Decode the Encrypted Image</h2>
              <label htmlFor="fileFormat">Select File Format:</label>
              <select
                id="fileFormat"
                value={fileFormat}
                onChange={handleFileFormatChange}
                style={{ width: '25%', marginBottom: '10px' }}
              >
                <option value="PNG">PNG</option>
                <option value="JPG">JPG</option>
                <option value="JPEG">JPEG</option>
                <option value="GIF">GIF</option>
              </select>

              <label htmlFor="decodeKey">Enter the Decode Key:</label>
              <input
                type="text"
                id="decodeKey"
                placeholder="Enter decode key"
                value={encryptKey}
                onChange={handleDecryptKey}
                style={{ width: '100%', marginBottom: '10px' }}
              />

              <label htmlFor="encryptedData">Paste the encrypted image data here:</label>
              <textarea
                id="encryptedData"
                placeholder="Paste encrypted data here"
                value={encryptedDataPasted}
                style={{ width: '100%', height: '150px', marginBottom: '10px' }}
                onChange={(e) => setEncryptedDataPasted(e.target.value)}
              />

              <button
                onClick={handleDecrypt}
                style={{
                  backgroundColor: 'maroon',
                  color: 'white',
                  border: 'none',
                  padding: '16px 32px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  fontSize: '1.2rem',
                  width: '100%', // Ensure button takes up entire width
                  marginBottom: '10px', // Add some margin to separate from the image
                }}
              >
                Decode File
              </button>
              {!decodeSuccess && <p>Decode unsuccessful.</p>}

              {decryptedData && decodeSuccess && (
                <div>
                  <Typography variant="h3" sx={{ color: 'black', fontSize: '20pt', marginTop: '20pt' }}>Image file decoding is successful if you see an image below.</Typography>
                  <hr />
                  <Typography variant="h4" sx={{ color: 'maroon', fontSize: '16pt', marginBottom: '10pt' }}>If you cannot see an image below, re-enter the correct encrypted data and decode key.</Typography>

                  <div style={{ width: '100%', overflow: 'hidden' }}>
                    <img src={decryptedData} alt="Decoded" style={{ width: '100%', height: 'auto', display: 'block' }} />
                  </div>

                  <button
                    onClick={handleDownload}
                    style={{
                      backgroundColor: 'royalblue',
                      color: 'white',
                      border: 'none',
                      padding: '16px 32px',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      fontSize: '1.2rem',
                      marginTop: '10px', // Add some margin to separate from the image
                      width: '100%', // Ensure button takes up entire width
                    }}
                  >
                    Download Image
                  </button>
                </div>
              )}
            </div>
          </Box>











        </Box>

        <Box mt={4} p={2} style={{ backgroundColor: themeMode === 'light' ? 'darkgrey' : 'dimgray' }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="https://freeweb3.infura-ipfs.io/ipfs/QmPQKEC6KVX72Pf1qK4h2jg8xCNWLbzWbSjupruAfCzsi1" alt="Get Id" style={{ width: '100px', margin: '0 10px' }} />
            <img src="https://freeweb3.infura-ipfs.io/ipfs/QmcTYpyFLE8HhjhAwFnR4Pju4t1UcTUKmw1euud4FZj3Aq" alt="Encrypt an ImageFile" style={{ width: '100px', margin: '0 10px' }} />
            <img src="https://freeweb3.infura-ipfs.io/ipfs/QmStCNFtYrAXEzDeTHMzbj8nVFwhbeW4idquz1bKae2umQ" alt="Encrypt to IPFS" style={{ width: '100px', margin: '0 10px' }} />
            <img src="https://freeweb3.infura-ipfs.io/ipfs/QmNuLvC6twHT9c9yw84U6xLT2XqCtF3SvhXJACjTiVdDtX" alt="CID to Chain" style={{ width: '100px', margin: '0 10px' }} />
            <img src="https://freeweb3.infura-ipfs.io/ipfs/QmbVpu2jZFp7FTvkbesMMQJmVAoBdCBtZYtV75o8bcMn1e" alt="Digital Artifacts" style={{ width: '100px', margin: '0 10px' }} />
          </Box>
        </Box>

        <Box mt={4} p={2} style={{ backgroundColor: themeMode === 'light' ? 'darkgrey' : 'dimgray' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">
              <a href="https://lexautomatico.com" target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>LexAutomatico</a>
            </Typography>
            <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'center' }}>
              <a href="https://lex.freeweb3.com" target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>SmartLaw</a>
            </Typography>
            <Typography variant="body1" sx={{ marginLeft: 'auto' }}>
              <a href="https://web3legaltools.com" target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>Web3 Legal Tools</a>
            </Typography>
          </Box>
        </Box>


      </Container>
    </ThemeProvider>
  );
};

export default App;
